import { isANumber } from "./generalUtil"
import { VendorType } from "@social-supermarket/social-supermarket-components"

export const getDaysAgo = (date: string): number => {
  let today = new Date()
  let createdOn = new Date(date)
  let msInDay = 24 * 60 * 60 * 1000

  createdOn.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return Math.floor((+today - +createdOn) / msInDay)
}

export const formatDateString = dateString => {
  const date = new Date(dateString)
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
}

export const formatDate = (date: Date) => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0")
  return `${year}-${month}-${day}`
}

export const dateRangeFilter = (
  date: string,
  startDate: Date | undefined,
  endDate: Date | undefined
) => {
  if (startDate && endDate) {
    return date >= formatDate(startDate) && date <= formatDate(endDate)
  } else if (startDate) {
    return date >= formatDate(startDate)
  } else if (endDate) {
    return date <= formatDate(endDate)
  } else {
    return true
  }
}

export const getBusinessDaysAhead = (days: number): Date => {
  const today = new Date()
  return addWorkDays(today, days)
}

export const getDaysAhead = (days: number): Date => {
  const today = new Date()
  return addDays(today, days)
}

const addWorkDays = (startDate: Date, daysToAdd: number): Date => {
  let dow = startDate.getDay()

  if (dow === 0) {
    daysToAdd++
  }

  if (dow + daysToAdd >= 6) {
    let remainingWorkDays = daysToAdd - (5 - dow)
    daysToAdd += 2
    if (remainingWorkDays > 5) {
      daysToAdd += 2 * Math.floor(remainingWorkDays / 5)
      if (remainingWorkDays % 5 === 0) {
        daysToAdd -= 2
      }
    }
  }
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const addDays = (startDate: Date, daysToAdd: number): Date => {
  startDate.setDate(startDate.getDate() + daysToAdd)
  return startDate
}

const getTwoDigitsFormat = (value: number): number | string => (value < 10 ? `0${value}` : value)

export const checkIsDeliveryEnabled = (date: Date, bankHolidays: string[]): boolean => {
  const day = date.getDay()
  const year = date.getFullYear()
  const month = getTwoDigitsFormat(date.getMonth() + 1)
  const dateValue = getTwoDigitsFormat(date.getDate())
  const formattedDate = `${year}-${month}-${dateValue}`

  return day !== 0 && day !== 6 && !bankHolidays.includes(formattedDate)
}

export const getSoonestShippingDate = (
  bankHolidays: string[] = [],
  hasStock: boolean,
  vendor: VendorType
): Date => {
  const delay =
    isANumber(vendor.nominatedDayDelay) && vendor.nominatedDayDelay > 0
      ? vendor.nominatedDayDelay
      : 1
  const timeStamp = "12:45"
  let currentDate = new Date()
  const currentDay = currentDate.getDay()
  const UKHour = currentDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/London",
  })
  let extraDays = UKHour > timeStamp || [0, 6].includes(currentDay) ? delay + 1 : delay

  do {
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
    if (!checkIsDeliveryEnabled(currentDate, bankHolidays)) continue
    extraDays -= 1
  } while (extraDays !== 0)

  if (!hasStock) {
    currentDate.setDate(currentDate.getDate() + 7)
  }

  return currentDate
}
