import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { getImpact, ImpactResponseType } from "../../api/impactApi"
import {
  colors,
  FullLinkButton,
  IconWrapper,
  ImpactOrderType,
  ImpactTypeType,
  LoginContext,
  ProblemType,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import RegionHeatmap from "../../components/chart/region/RegionHeatmap"
import {
  getOrders,
  getSpendByAttribute,
  getSpendBySupplier,
  getSupplierImpactForOrder,
  mapForPdf,
} from "./ImpactUtil"
import DonutSection from "../../components/chart/DonutSection"
import SpendDonuts from "./SpendDonuts"
import { DateRangePicker, Panel } from "rsuite"
import { DateRange } from "rsuite/DateRangePicker"
import { dateRangeFilter } from "../../util/dateUtil"
import ImpactOrderTable from "./ImpactOrderTable"
import ImpactTile from "./ImpactTile"
import { downloadReportFromJson } from "../../api/platform/reportApi"
import PdfDownloadConfigModal from "./PdfDownloadConfigModal"
import ToggleButton from "../../components/button/ToggleButton"

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-top: 40px;
`
const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const Title = styled.h1`
  font-family: "Minion", "Verdana", sans-serif;
  font-size: 2em;
  margin: 0;
`
const Top = styled.div`
  margin: 5px;
  margin-bottom: 20px;
`
const Spend = styled.div`
  display: flex;
`
const Impacts = styled.div`
  margin-bottom: 20px;
  display: flex;
`
const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
const SecondaryControls = styled.div`
  display: flex;
  align-items: center;
`
const NOrders = styled.div`
  font-size: 0.8em;
  margin-left: 10px;
`
const StyledPanel = styled(Panel)`
  margin: 5px;
  flex: 1;
  background: white;
`
const StyledFullLinkButton = styled(FullLinkButton)`
  margin-left: auto;
`

const StyledImpactTile = styled(ImpactTile)`
  margin: 10px;
`
const StyledIconWrapper = styled(IconWrapper)`
  && {
    margin-right: 5px !important;
  }
`

const StyledDonutSection = styled(DonutSection)`
  margin: 5px;
  background: white;
`

const defaultImpactState: ImpactResponseType = {
  certifications: {},
  impactTypes: {},
  orderImpacts: [],
  allOrderImpacts: [],
  problems: {},
  sdgs: {},
  ssmgs: {},
  suppliers: {},
}

export interface OrderType {
  key: string
  date: string
  source: string
  reference: string
  spend: number
  quantity: number
}

export interface ImpactType {
  amount: number
  impactType: ImpactTypeType
  problem: ProblemType
}

interface Props {}

const ImpactDashboard: FC<Props> = () => {
  const { token, user } = useContext(LoginContext)
  const [impactScope, setImpactScope] = useState<string>("all-customers")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [filteredOrders, setFilteredOrders] = useState<ImpactOrderType[]>([])
  const [supplierSpend, setSupplierSpend] = useState<{ [key: string]: number }>({})
  const [dateRange, setDateRange] = useState<DateRange | undefined>()
  const [impactResponse, setImpactResponse] = useState<ImpactResponseType>({
    ...defaultImpactState,
  })
  const [fullImpacts, setFullImpacts] = useState<ImpactType[]>([])
  const [showModal, setShowModal] = useState<boolean>(false)

  const orders = getOrders(filteredOrders)

  useEffect(() => {
    const get = async () => {
      const impact = await getImpact(token, user.ID, "2010-01-01", "2030-01-01")
      setImpactResponse(impact)
      setIsLoading(false)
    }
    get()
  }, [token, user])

  useEffect(() => {
    const selectedOrderImpacts =
      impactScope === "all-customers" ? impactResponse.allOrderImpacts : impactResponse.orderImpacts
    const filtered = selectedOrderImpacts.filter(order =>
      dateRangeFilter(
        order.date,
        dateRange ? dateRange[0] : undefined,
        dateRange ? dateRange[1] : undefined
      )
    )
    setFilteredOrders(filtered)

    const impactMap: { [key: string]: number } = {}

    filtered.forEach(order => {
      const productImpacts =
        impactResponse?.suppliers[order.supplierCode]?.productGroups[order.productGroupCode]
          ?.impacts || []
      productImpacts.forEach(productImpact => {
        if (!impactMap[productImpact.impactCode]) {
          impactMap[productImpact.impactCode] = 0
        }
        const thisImpact = getSupplierImpactForOrder(productImpact, order)
        impactMap[productImpact.impactCode] += thisImpact
      })
    })

    const fullImpacts = mapImpactAmountsToFullImpact(impactMap)
    setFullImpacts(fullImpacts)
  }, [dateRange, impactResponse, impactScope])

  const mapImpactAmountsToFullImpact = (impactAmounts: { [key: string]: number }): ImpactType[] => {
    return Object.keys(impactAmounts).map(impactCode => {
      const { impactTypes, problems, ssmgs } = impactResponse
      const impactType = impactTypes[impactCode]
      const problem = problems[impactType.problem]
      return {
        amount: impactAmounts[impactCode],
        problem,
        impactType,
      }
    })
  }

  const handleDownloadReport = async (title: string) => {
    const json = mapForPdf(impactResponse)
    console.log("IMPACTJSON", json)
    setShowModal(false)
    setIsDownloading(true)
    try {
      await downloadReportFromJson(token, user.ID, json, title, false)
    } catch (e) {
      alert(e)
    } finally {
      setIsDownloading(false)
    }
  }

  useEffect(() => {
    const spend = getSpendBySupplier(filteredOrders)
    setSupplierSpend(spend)
  }, [filteredOrders])

  const handleDatesChanged = (value: DateRange | null) => {
    if (value) {
      setDateRange(value)
    }
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} label={"Loading your impact.."} />
      <Spinner isLoading={isDownloading} label={"Downloading report.."} />

      <Top>
        <TitleBar>
          <Title>Impact Summary</Title>
          <ToggleButton
            options={[
              { label: "All Customers", key: "all-customers" },
              { label: "Your Impact", key: "your-impact" },
            ]}
            selected={impactScope}
            onSelect={key => setImpactScope(key)}
          />
        </TitleBar>
        <Controls>
          <DateRangePicker
            format={"dd/MM/yyyy"}
            character={" - "}
            placeholder={"Select date range"}
            onChange={handleDatesChanged}
            onClean={() => setDateRange(undefined)}
          />
          <NOrders>
            Estimating impact for {orders.length} order{orders.length > 1 && "s"}
          </NOrders>
          <StyledFullLinkButton onClick={() => setShowModal(true)}>
            <StyledIconWrapper name={"download"} />
            Download PDF Report
          </StyledFullLinkButton>
        </Controls>
        <SecondaryControls></SecondaryControls>
      </Top>
      <Impacts>
        {fullImpacts.map(fullImpact => (
          <StyledImpactTile
            color={colors.primaryLight}
            impact={fullImpact}
            ssmgs={impactResponse.ssmgs}
          />
        ))}
      </Impacts>
      {!isLoading && (
        <div>
          {user.roles.includes("administrator") && false && (
            <StyledPanel collapsible header={`Orders (${filteredOrders.length})`}>
              <ImpactOrderTable orders={filteredOrders} />
            </StyledPanel>
          )}
          <Spend>
            <StyledPanel header="Spend by region">
              <RegionHeatmap
                regionSpend={getSpendByAttribute(supplierSpend, impactResponse.suppliers, "region")}
              />
            </StyledPanel>
            <StyledDonutSection
              data={getSpendByAttribute(supplierSpend, impactResponse.suppliers, "employees")}
              title={"Spend by company size (n. employees)"}
            />
          </Spend>
          <SpendDonuts supplierSpend={supplierSpend} suppliers={impactResponse.suppliers} />
        </div>
      )}
      <PdfDownloadConfigModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onDownload={handleDownloadReport}
      />
    </Container>
  )
}

export default ImpactDashboard
