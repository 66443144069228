import * as React from "react"
import styled from "styled-components"
import { FC, useContext, useState } from "react"
import {
  BasicTextInput,
  loginApi,
  LoginContext,
  Modal,
  ModalFooter,
  PrimaryButton,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import ModalBody from "rsuite/ModalBody"
import ImageField from "../gifting/gift-list/designer/fields/ImageField"
import { GIFTING_MODULES } from "../gifting/gift-list/designer/GiftListPageDesignerForm"
import { getConfig } from "../../../config"
import TitleField from "../gifting/gift-list/designer/fields/TitleField"

const StyledModal = styled(Modal)`
  width: 500px;
  height: auto;
  max-height: 100vh;
  overflow: auto;
`
const StyledTextInput = styled(BasicTextInput)`
  width: 300px;
  input {
    width: 100%;
  }
`
const Body = styled.div`
  flex: 1;
  padding: 10px;
`
const Label = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`

interface Props {
  show: boolean
  onClose: () => void
  onDownload: (title: string) => {}
}

const PdfDownloadConfigModal: FC<Props> = ({ show, onClose, onDownload }) => {
  const { user, token } = useContext(LoginContext)
  const [state, setState] = useState<any>({ logoSrc: user.logoSrc })
  const [isUpdatingLogo, setIsUpdatingLogo] = useState<boolean>(false)

  const handleLogoChange = async (name: string, value: File) => {
    setIsUpdatingLogo(true)
    setState((prev: any) => ({ ...prev, [name]: value }))
    if (name === "logoFile" && value) {
      await loginApi.updateLogo(getConfig().baseUrl, token, user.ID, value)
    }
    setIsUpdatingLogo(false)
  }

  return (
    <StyledModal show={show} onClose={onClose} title={"Download PDF Report"}>
      <Spinner isLoading={isUpdatingLogo} label={"Updating logo..."} />
      <Body>
        <Label>Report Title</Label>
        <StyledTextInput
          name={"title"}
          onChange={e => setState(prev => ({ ...prev, title: e.target.value }))}
          value={state.title}
          isValid={true}
        />
        <ImageField
          name={"logo"}
          label={"Add your company logo"}
          state={state}
          onChange={handleLogoChange}
          modules={GIFTING_MODULES}
        />
      </Body>
      <ModalFooter>
        <PrimaryButton
          slim
          disabled={!state.logoFile && !state.logoSrc}
          onClick={() => onDownload(state.title)}
        >
          Download Report
        </PrimaryButton>
      </ModalFooter>
    </StyledModal>
  )
}

export default PdfDownloadConfigModal
