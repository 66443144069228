import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import DonutSection from "../../components/chart/DonutSection"
import { getSpendByAttribute, getSpendByMultiAttribute } from "./ImpactUtil"
import { ImpactSupplierType } from "@social-supermarket/social-supermarket-components"

const Container = styled.div``
const Body = styled.div`
  display: flex;
`
const Title = styled.div`
  font-weight: bold;
`

const StyledDonutSection = styled(DonutSection)`
  margin: 5px;
  background: white;
`

interface Props {
  supplierSpend: { [key: string]: number }
  suppliers: { [key: string]: ImpactSupplierType }
}

const SpendDonuts: FC<Props> = ({ supplierSpend, suppliers }) => {
  return (
    <Container>
      <Body>
        <StyledDonutSection
          data={getSpendByAttribute(supplierSpend, suppliers, "legalStructure")}
          title={"Spend by legal structure"}
        />
        <StyledDonutSection
          data={getSpendByMultiAttribute(supplierSpend, suppliers, "founderEthnicities")}
          title={"Spend by founder ethnicity"}
        />
        <StyledDonutSection
          data={getSpendByMultiAttribute(supplierSpend, suppliers, "founderGenders")}
          title={"Spend by founder gender"}
        />
      </Body>
    </Container>
  )
}

export default SpendDonuts
